import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Slide, ButtonGroup } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import Button from '../../components/Button';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const AuthenticatedTopBar = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      <TopBar authUser={authUser} />
    }
  </AuthUserContext.Consumer>
);

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();
  
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const MenuButtons = ({ authUser }) => {
  return (
    <ButtonGroup
      color="secondary"
      size="large"
      aria-label="large full width contained secondary button group">
      <Button to={ROUTES.LANDING}>Landing</Button>
      <Button to={ROUTES.HOME}>Home</Button>
      {authUser && (<Button to={ROUTES.ACCOUNT}>Account</Button>)}
      {(authUser && !!authUser.roles[ROLES.ADMIN]) && (<Button to={ROUTES.ADMIN}>Admin</Button>)}
    </ButtonGroup>
  )
}

const TopBar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HideOnScroll {...props}>
        <AppBar color="default">
          <Toolbar>
            <MenuButtons authUser={props.authUser} />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}

export default AuthenticatedTopBar;