import React, { Fragment, useState, useLayoutEffect } from 'react';
import Container from '@material-ui/core/Container';

import getFirebase, { FirebaseContext } from './Firebase';
import withAuthentication from './Session/withAuthentication';
import AuthenticatedTopBar from '../components/AuthenticatedTopBar';

const useFirebase = () => {
  const [ firebase, setFirebase] = useState(null);
  useLayoutEffect(() => {
    const loadFirebase = async () => {
      const app = import('firebase/app');
      const auth = import('firebase/auth');
      const database = import('firebase/database');
  
      Promise.all([app, auth, database]).then(values => {
        const firebase = getFirebase(values[0]);  
        setFirebase(firebase);
      });
    };
    if (firebase === null) {
      loadFirebase();
    }
  }, [firebase]);
  return firebase;
}

const Layout = (props) => {
  const firebase = useFirebase();
  return (
    <FirebaseContext.Provider value={firebase}>
      <AppWithAuthentication {...props} />
    </FirebaseContext.Provider>
  );
}

const AppWithAuthentication = withAuthentication(({ children }) => (
  <Fragment>
    <AuthenticatedTopBar />
    <Container maxWidth="sm">
    {children}
    </Container>

  </Fragment>
));

export default Layout;
