import React, { useState } from 'react';
import { Link } from 'gatsby';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  email: '',
  error: null,
};

const PasswordForgetForm = props => {
  const [state, setState] = useState(INITIAL_STATE);
  const onSubmit = event => {
    const { email } = this.state;

    props.firebase
      .doPasswordReset(email)
      .then(() => {
        setState(INITIAL_STATE);
      })
      .catch(error => {
        setState({ error });
      });

    event.preventDefault();
  };
  const onChange = event => {
    setState({ [event.target.name]: event.target.value });
    event.persist();
  };
  const { email, error } = state;

  const isInvalid = email === '';

  return (
    <form onSubmit={onSubmit}>
      <input
        name="email"
        value={email}
        onChange={onChange}
        type="text"
        placeholder="Email Address"
      />
      <button disabled={isInvalid} type="submit">
        Reset My Password
      </button>

      {error && <p>{error.message}</p>}
    </form>
  );

}


const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default withFirebase(PasswordForgetForm);

export { PasswordForgetLink };
